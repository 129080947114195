
/* You can add global styles to this file, and also import other style files */

/* @import "~bootstrap/dist/css/bootstrap.css"; */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
    margin: 0;
    line-height: normal;
  }
  
  /* global css file */
  :root {
    /* fonts */
    --text-md-regular: Inter;
    --font-kanit: Kanit;
    --font-inherit: inherit;
    --font-font-awesome-5-brands: "Font Awesome 5 Brands";
    --font-font-awesome-5-free: "Font Awesome 5 Free";
  
    /* font sizes */
    --font-size-mini: 15px;
    --font-size-xl: 20px;
    --font-size-sm: 14px;
    --text-md-regular-size: 16px;
    --font-size-lg: 18px;

    --font-font-awesome-5-free: "Font Awesome 5 Free";
    --paragraph-regular: Lato;
    --h2-semibold: Kanit;
    --font-font-awesome-5-free: "Font Awesome 5 Free";
    --text-md-regular: Inter;
    --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  
    /* font sizes */
    --font-size-xl: 20px;
    --font-size-sm: 14px;
    --font-size-3xl: 22px;
    --paragraph-regular-size: 17px;
    --font-size-13xl: 32px;
    --font-size-26xl: 45px;



    --text-md-regular-size: 16px;
    --font-size-lg: 18px;

    --font-size-xs: 12px;
    --font-size-5xl: 24px;
    --font-size-61xl: 80px;
    --h2-semibold-size: 55px;
  
    /* Colors */
    --colors-white: #fff;

    --color-black: #000;
    --color-darkgray: #b3b3b3;
    --kraft-d-green: #025a00;
    --gray: #eee;
    --kraft-light-green: #91c490;
    --border-event: #ddd;
    --colors-primary-black: #292929;
    --primary-dark: #141619;
  
    /* Gaps */
    --gap-2xl: 21px;
    --gap-13xl: 32px;
    --gap-14xl: 33px;
  
    /* border radiuses */
    --br-8xs: 5px;
  
    /* Effects */
    --shadows-shadow-10: 0px 4px 5px rgba(0, 0, 0, 0.05);

    --color-darkslategray: #41444b;
    --colors-primary-black: #292929;
    --color-gray-100: #16213e;
    --primary-dark: #141619;
    --kraft-light-green: #91c490;
    --kraft-d-green: #025a00;
    --color-darkseagreen: #91c490;
  
    /* Gaps */
    --gap-13xl: 32px;
    --gap-14xl: 33px;

  }


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --fonts-peragraph-reguler: "Myriad Pro";
  --text-sm-regular: Inter;
  --font-font-awesome-5-free: "Font Awesome 5 Free";

  /* font sizes */
  --fonts-peragraph-reguler-size: 15px;
  --font-size-base: 16px;
  --fonts-place-holder-small-size: 14px;
  --fonts-heading-h4-size: 18px;

  /* Colors */
  --colors-white: #fff;
  --kraft-d-green: #025a00;
  --color-seagreen: #518350;
  --color-darkolivegreen: #3d6b3d;
  --colors-buttun-gray-10: #eff2f5;
  --color-red: #ff0000;
  --color-black: #000;
  --primary-dark: #141619;
  --color-gray-100: #000a1b;
  --border-event: #ddd;
  --color-gainsboro-100: #dbdbdb;
  --gray-500: #667085;
  --color-lightgray: #d0d5dd;

  /* Gaps */
  --gap-7xs: 6px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-sm: 14px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-5xs: 8px;
}


